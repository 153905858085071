import { EditNote, AccessAlarm } from '@mui/icons-material'
import { Box, Checkbox, Divider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { convertDurationToMinutes } from 'utils'
import { GameCardChipStyles } from 'common/components/gameCard/components/chipList/GameCardChipList.styles'
import { useAuth } from 'context'
import { TEACHER_TYPES } from 'services'
import { ModeCardStyles } from './ModeCard.styles'
import { ModeCardPropTypes } from './ModeCard.types'

export const ModeCardComponent: React.FC<ModeCardPropTypes> = ({
  gameMode,
  showCheckbox,
  checkbox
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const shouldDisplayPremiumBanner =
    gameMode.isPremium && user?.teacherType === TEACHER_TYPES.GUEST

  const playTimeMinutes = convertDurationToMinutes(gameMode.playTime)

  return (
    <ModeCardStyles
      isSelected={checkbox?.checked}
      isDisabled={shouldDisplayPremiumBanner}
    >
      <Box display='flex' flexDirection='column' gap={2} py={2} px={3} flex={1}>
        <Box
          display='flex'
          justifyContent='space-between'
          gap={2}
          flexWrap='wrap'
        >
          <Box display='flex' alignItems='center' gap={2}>
            {showCheckbox && checkbox && (
              <Checkbox
                disabled={shouldDisplayPremiumBanner}
                color='secondary'
                sx={{ ml: -1.5 }}
                {...checkbox}
              />
            )}
            <Typography
              variant='subtitle1'
              fontWeight={({ typography }) => typography.fontWeightBold}
            >
              {gameMode.mode.name}
            </Typography>
          </Box>
          {Boolean(gameMode.boards) && (
            <Box display='flex' alignItems='center' gap={1}>
              <EditNote color='primary' sx={{ fontSize: 24 }} />
              <Typography variant='body2'>
                {t('components.gameCard.boards', {
                  boardsCount: gameMode.boards
                })}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography variant='body2'>{gameMode.description}</Typography>
      </Box>
      {Boolean(gameMode.tags.length) && (
        <>
          <Divider />
          <Box display='flex' alignItems='center' gap={2} py={2} px={3}>
            <Typography
              variant='caption'
              fontWeight={({ typography }) => typography.fontWeightBold}
            >
              {t('components.gameCard.skills')}
            </Typography>
            <Box display='flex' flexWrap='wrap' gap={1}>
              {gameMode.tags.map((tag) => (
                <GameCardChipStyles
                  key={tag.id}
                  variant='light'
                  label={tag.name}
                />
              ))}
            </Box>
            <Box display='flex' alignItems='center' gap={1} ml='auto'>
              <AccessAlarm color='primary' />
              <Typography
                variant='caption'
                style={{
                  fontSize: '1.0em',
                  fontWeight: 'bold'
                }}
              >
                {t('pages.content.playTime')}:
              </Typography>
              <Typography
                variant='caption'
                style={{
                  fontSize: '1.0em',
                  fontWeight: 'normal'
                }}
              >
                {playTimeMinutes} {t('general.min')}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </ModeCardStyles>
  )
}
